<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="3">
          <b-form-group>        
            <h5>Laboratorio</h5>    
            <b-form-select
              v-model="labSelected"
              :options="labList"
            />            
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>        
            <h5>Tipo de Muestra</h5>
            <b-form-select
            @click="menu2 = true"
              v-model="pageSelected"
              :options="pagesList"
            />            
          </b-form-group>
        </b-col>
        <b-col md="3">
          <!-- Cambios para mejor visualizacion -->
          <!-- <b-form-group>
            <h5>Hora de Toma</h5>
            <flat-pickr
              v-model="horaReal"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"              
            />
          </b-form-group> -->
          <h5>Hora de Toma</h5>
          <template>
            <div data-app id="horaTomaMuestreo">            
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="horaReal"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >            
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaReal"                  
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="form-control mt-0"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="horaReal"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu.save(horaReal)"
                ></v-time-picker>
              </v-menu>
            </div>
          </template>
        </b-col>        
        <b-col md="3">
          <b-form-group>
            <h5>Fecha</h5>
            <flat-pickr
              v-model="date"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-form-group>
        </b-col>        
      </b-row>
    </b-card> 
    <b-card v-if="singleData && tags && pageSelected"> 
      <b-tabs :set="averageItems = Object.keys(tags).length / Object.keys(singleData.groups).length">
      <template v-for="(a, k) in singleData.groups">             
        <b-tab :key="k">
          <template #title>
            <span>{{a}}</span>
          </template>     
          <template v-if="singleData.elementosGenerales">
              <b-form-group
              v-for="(gE, gK) in singleData.elementosGenerales"
              :label="gE"
              :label-for="gK+gE"
              :key="gK+gE"
              class="relative"
              :class="averageItems > 10 ? 'w-1/2 inline-block px-1' : ''"
            >                                    
              <b-form-input :id="gK+gE" :placeholder="gE" 
                :name="generateTag(pageSelected,a,gE)" 
                v-model="tags[generateTag(pageSelected,a,gE)]" 
                type="number" 
                autocomplete="off" 
                
              />
            </b-form-group>
          </template>       
            <template v-if="singleData.elements && singleData.elements[a]">
              <b-form-group
              v-for="(e, e_k) in singleData.elements[a]"
              :label="e"
              :label-for="e"
              :key="e_k"
              class="relative"
              :class="averageItems > 10 ? 'w-1/2' : ''"
            >                                    
              <b-form-input :id="k+e" :placeholder="e" 
                :name="generateTag(pageSelected,a,e)" 
                v-model="tags[generateTag(pageSelected,a,e)]" 
                type="number" 
                autocomplete="off" 
                
              />
            </b-form-group>
            </template>            
            <b-button variant="primary" @click="saveData">Guardar Datos {{a}}</b-button>
        </b-tab>                        
      </template>        
      </b-tabs>
    </b-card>      
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCol,
  BRow,
  BCalendar,  
  BPopover,
  BFormSelect,
  BFormInvalidFeedback,
  BTab,
  BTabs
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import laboratorio from '@/../apis/Laboratorio'
import firebase from 'firebase/app'
import 'firebase/database'
export default {
  name: 'MuestrasLab',
  data() {    
    return {
      singleData: null,
      horaRelativa: null,
      pageSelected:null,
      pagesList: [],
      optionsTime: [],
      labSelected:null,
      labList:[],
      date: null,
      muestras:[],
      horaReal: null,
      tags:null,
      validations:{
        hour: false,
        programedHour: false
      },
      //new picker
      menu2: false,
      time2:null
    };
  },
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCol,
    BRow,
    vSelect,
    BCalendar,
    BPopover,
    BFormSelect,
    flatPickr,
    BFormInvalidFeedback,
    BTabs,
    BTab
  },
  directives: {
    Ripple,
  },  
  methods: {
    setToday() {
      const now = new Date();
      this.date = now.getFullYear() +'-' + (Number(now.getMonth()) + 1) + '-' + ("0" + now.getDate()).slice(-2)      
      this.horaReal = ("0" + now.getHours()).slice(-2)+':'+("0" + now.getMinutes()).slice(-2);
      let hour = now.getHours()
      hour = ("0" + hour).slice(-2);      
      const programed = hour+':'+ '00'      
      const isin = this.optionsTime.includes(programed)      
      isin ? this.horaRelativa = programed :  ''
      this.optionsTime[0] ? this.horaRelativa = this.optionsTime[0] : this.horaRelativa = programed
    },
    renderOptions(valor){
      this.pagesList = []
      Object.entries(this.muestras).forEach((element, key)=>{        
        if(element[1].lab == valor){
          const oj = {value: element[0], text: element[1].name}        
          this.pagesList.push(oj)
        }
      })
      this.pagesList.sort(function(a, b){
          if(a.text < b.text) { return -1; }
          if(a.text > b.text) { return 1; }
          return 0;
      })  
      this.pageSelected = this.pagesList[0] ? this.pagesList[0].value : ''
      if(this.muestras[this.pageSelected]){
        this.singleData = this.muestras[this.pageSelected];
        this.optionsTime = this.singleData.times
      }                  
    },
    renderObjectSelected(){      
      if ((!this.singleData.elements && !this.singleData.elementosGenerales) || !this.pageSelected) return
      const objResource = []
      let objChild = []
      let pName  = this.singleData.name            
      //Elementos por grupo
      Object.keys(this.singleData.groups).forEach((el_g)=>{
          const gGroup = this.singleData.groups[el_g]
          let group = this.singleData.groups[el_g]          
          if(this.singleData.elementosGenerales){
            Object.keys(this.singleData.elementosGenerales).forEach((el_elem)=>{            
              const elemento = (this.singleData.elementosGenerales[el_elem]).toLowerCase()
              pName = pName.toLowerCase()
              group = group.toLowerCase()
              const itemParsed = (pName + '.' + group + '.' + elemento).replaceAll(' ', '_')            
              objChild[itemParsed] = ''                     
            })
          }
          if(this.singleData.elements){
            if(this.singleData.elements[gGroup]){
              Object.keys(this.singleData.elements[gGroup]).forEach((el_elem)=>{                          
                const elemento = (this.singleData.elements[gGroup][el_elem]).toLowerCase()
                pName = pName.toLowerCase()
                group = group.toLowerCase()
                const itemParsed = (pName + '.' + group + '.' + elemento).replaceAll(' ', '_')            
                objChild[itemParsed] = ''                     
              })
            }
          }     
          objResource[pName] = objChild
        })
      this.tags = Object.assign({}, objResource[pName])      
    },
    generateTag(name, group, element){      
      const parentName = this.muestras[name].name            
      const tag = parentName.toLowerCase() + '.' + group.toLowerCase() + '.' + element.toLowerCase()
      const finalTag = tag.replaceAll(' ', '_')                  
      return finalTag
    },
    saveData(){
      if (!this.horaRelativa){
        this.$bvToast.toast('Seleccione la hora programada.', {
          title: 'Error...',
          variant: 'danger',
          solid: true,
        })          
      }else{
        const dataToSave = {
        name: this.singleData.name,
        data: this.tags,
        key: this.pageSelected,
        horaProgramada: this.horaRelativa,
        horaReal: this.horaReal,
        fecha: this.date,
        lab: this.labSelected
      }
      laboratorio.actions.enviarRegistro(null,dataToSave).then((response)=>{
        if(response.data.status == 200){
          this.$bvToast.toast(response.data.message, {
            title: 'Correcto...',
            variant: 'success',
            solid: true,
          })
          this.renderObjectSelected()
        }else{
          this.$bvToast.toast('Ha ocurrido un error inesperado.', {
            title: 'Error...',
            variant: 'danger',
            solid: true,
          })
        }
      })
      }      
    }
  },
  created() {        
    firebase.database().ref('laboratorios').once('value', (snap)=>{
      this.labList = snap.val()      
      this.labList.sort(function(a, b){
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
      }) 
      firebase.database().ref('muestreo').once('value', (snap)=>{
        this.muestras = snap.val()                  
        this.labSelected = this.labList[0]      
        this.renderOptions(this.labSelected)
        this.renderObjectSelected()
        this.setToday()
      })  
    })
  },
  watch:{
    labSelected: function (val){        
        this.renderOptions(val)
        this.renderObjectSelected()
    },
    pageSelected: function (val){    
      if(this.muestras[val]){
        this.singleData = this.muestras[val]      
        this.optionsTime = this.muestras[val].times
        this.renderObjectSelected()
      }      
    }    
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand,
.primary,
.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__item--active,
.v-time-picker-clock__hand,.v-time-picker-clock__item--active{
  background: $primary;
}
.v-time-picker-clock__hand::before{
  border-color: $primary;
}
.dark-layout .v-time-picker-clock{
  background: #283046;
}
.dark-layout .v-time-picker-clock__inner{
  color: #b4b7bd;
}
.dark-layout .v-time-picker-clock__container{
  background: #161D31;
}
.v-picker--time{
  border: none;
}
.v-input__prepend-outer,
.theme--light.v-text-field>.v-input__control>.v-input__slot:before,
.v-text-field>.v-input__control>.v-input__slot:after{
  display: none;
}
#horaTomaMuestreo .menuable__content__active{
  left: 0!important;
}
.v-text-field__slot input{
  padding: 0;
  padding-top: 2px;
}
.dark-layout .v-input.form-control{
  border-color:#404656;
  color: #b4b7bd;
  background: transparent;
}
.dark-layout .v-text-field__slot input{
  color: #b4b7bd!important;
}
.v-input input{
  color:#6e6b7b!important;
}
</style>